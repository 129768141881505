export default (type,url,title) => {

  switch(type)
  {
    case 'facebook':
      window.open('https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent(url),'','width=626,height=436');
      break;

    case 'twitter':
      window.open('https://twitter.com/share?text='+encodeURIComponent(title)+'&url='+encodeURIComponent(url),'','width=626,height=436');
      break;

    case 'line':
      // https://social-plugins.line.me/lineit/share?url={URL}
      window.open('https://social-plugins.line.me/lineit/share?url='+encodeURIComponent(url),'','width=626,height=436');
      break;

  }
}
